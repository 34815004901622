import { capitalize } from 'stillnovel/utils';

import ArtPrint from './Builders/ArtPrint';

const defaultArtPrintAttributes = category => project => {
    return [
        {
            key: 'Design',
            value: `${category} - ${capitalize(project.metadata.style)}`,
        },
        project.metadata?.designNote && {
            key: 'Note',
            value: project.metadata?.designNote,
        },
    ].filter(attributes => attributes && attributes?.value !== '');
};

const defaultArtPrintDashboardTitle = category => () => `${category} Art Print`;

const defaultSnapshotDashboardTitle = category => () =>
    `${category} Snapshot Print`;

const simplePrintSizes = [
    '10x10',
    '11x15',
    '15x11',
    '16x20',
    '20x16',
    '20x24',
    '24x20',
];

const vintageCommon = {
    photoTargetWidth: 967,
    photoTargetHeight: 1317,
};

const modernCommon = {
    photoTargetWidth: 1600,
    photoTargetHeight: 2000,
};

const config = {
    'birth-stat': {
        title: 'Birth Stat',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `birth-stat-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Birth Stat'),
        dashboardTitle: defaultArtPrintDashboardTitle('Birth Stat'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project => {
            const style = project?.metadata?.style?.toLowerCase();
            return `${project.builderHandle}-${style || 'modern'}`;
        },
    },
    'birth-stat-snapshot': {
        title: 'Birth Stat',
        parentCategory: 'Snapshot Print 11x14',
        buildSteps: ['', 'details', 'review', 'frame'],
        shopifyProductHandle: `art-print-11x14`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Birth Stat'),
        dashboardTitle: defaultSnapshotDashboardTitle('Birth Stat'),
        projectDefaults: { style: 'Style 02', filterSetting: 'default' },
        styles: [
            {
                styleHandle: 'Style 01',
                photoTargetWidth: 330,
                photoTargetHeight: 453,
            },
            {
                styleHandle: 'Style 02',
                photoTargetWidth: 524,
                photoTargetHeight: 604,
            },
        ],
        printWidth: 11,
        printHeight: 14,
        previewLoaderHandle: project => {
            const style = project?.metadata?.style?.toLowerCase();
            return `${project.builderHandle}-${style || 'Style 02'}`;
        },
    },
    travel: {
        title: 'Travel',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `travel-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Travel'),
        dashboardTitle: defaultArtPrintDashboardTitle('Travel'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    'travel-snapshot': {
        title: 'Travel',
        parentCategory: 'Snapshot Print 11x14',
        buildSteps: ['', 'details', 'review', 'frame'],
        shopifyProductHandle: `art-print-11x14`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Travel'),
        dashboardTitle: defaultSnapshotDashboardTitle('Travel'),
        projectDefaults: { style: 'Style 02', filterSetting: 'default' },
        styles: [
            {
                styleHandle: 'Style 01',
                photoTargetWidth: 330,
                photoTargetHeight: 453,
            },
            {
                styleHandle: 'Style 02',
                photoTargetWidth: 524,
                photoTargetHeight: 604,
            },
        ],
        printWidth: 11,
        printHeight: 14,
        previewLoaderHandle: project => {
            const style = project?.metadata?.style?.toLowerCase();
            return `${project.builderHandle}-${style || 'Style 02'}`;
        },
    },
    wedding: {
        title: 'Wedding',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `wedding-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Wedding'),
        dashboardTitle: defaultArtPrintDashboardTitle('Wedding'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    couples: {
        title: 'Couples',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `couples-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Couples'),
        dashboardTitle: defaultArtPrintDashboardTitle('Couples'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    engagement: {
        title: 'Engagement',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'couple', 'details', 'stats', 'review', 'frame'],
        shopifyProductHandle: `engagement-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Engagement'),
        dashboardTitle: defaultArtPrintDashboardTitle('Engagement'),
        projectDefaults: { style: 'modern' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    'all-about-you': {
        title: 'All About You',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'stats', 'review', 'frame'],
        shopifyProductHandle: `all-about-you-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('All About You'),
        dashboardTitle: defaultArtPrintDashboardTitle('All About You'),
        projectDefaults: { style: 'modern', isMemorial: false },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    memorial: {
        title: 'Memorial',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'stats', 'review', 'frame'],
        shopifyProductHandle: `memorial-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Memorial'),
        dashboardTitle: defaultArtPrintDashboardTitle('Memorial'),
        projectDefaults: { style: 'modern', isMemorial: true },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    parent: {
        getNewHandle: props => {
            const subject = props?.metadata?.subject === 'Dad' ? 'dad' : 'mom';
            return `parent-${subject}`;
        },
        getNewConfig: props => {
            return config[config.parent.getNewHandle(props)];
        },
    },
    'parent-dad': {
        title: 'Father',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'review', 'frame'],
        shopifyProductHandle: `parent-dad-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Father'),
        dashboardTitle: defaultArtPrintDashboardTitle('Father'),
        projectDefaults: { style: 'modern', subject: 'dad' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    'parent-mom': {
        title: 'Mother',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'review', 'frame'],
        shopifyProductHandle: `parent-mom-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Mother'),
        dashboardTitle: defaultArtPrintDashboardTitle('Mother'),
        projectDefaults: { style: 'modern', subject: 'mom' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    pet: {
        title: 'Pet',
        parentCategory: 'Art Print 16x20',
        buildSteps: ['', 'details', 'details2', 'review', 'frame'],
        shopifyProductHandle: `pet-art-print-16x20`,
        component: ArtPrint,
        // shopifyLineItemAttributes: defaultArtPrintAttributes('Pet'),
        dashboardTitle: defaultArtPrintDashboardTitle('Pet'),
        projectDefaults: { style: 'modern', pet: 'DOG' },
        styles: [
            {
                styleHandle: 'modern',
                ...modernCommon,
            },
            {
                styleHandle: 'vintage',
                ...vintageCommon,
            },
        ],
        printWidth: 16,
        printHeight: 20,
        previewLoaderHandle: project =>
            `${project.builderHandle}-${
                project?.metadata?.style?.toLowerCase() || 'modern'
            }`,
    },
    ...simplePrintSizes.reduce((o, size) => {
        const [w, h] = size.split('x');
        o[`simple-framed-print-${size}`] = {
            parentCategory: 'Custom Framed Print',
            buildSteps: ['', 'review', 'frame'],
            shopifyProductHandle: `simple-framed-print-${size}`,
            component: ArtPrint,
            shopifyLineItemAttributes: defaultArtPrintAttributes(
                `Custom Framed Print ${w}" x ${h}"`
            ),
            dashboardTitle: () => `Custom Framed Print - ${w}x${h}"`,
            projectDefaults: { style: 'modern' },
            printWidth: parseInt(w),
            printHeight: parseInt(h),
            previewLoaderHandle: project => `${project.builderHandle}`,
        };
        return o;
    }, {}),
};

export default config;
